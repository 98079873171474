import React from 'react';
import './App.css';

function App() {
  return (
      <>
          <div className="App">
              <h1>Willkommen zur VoxelGames Info Seite.</h1>
              <div className="App-body">
                  <p>Wir sind ein kleines Team, welches sich auf die Fokussierung von Hosting spezialisiert hat.</p>
                  <p>Wir sind immer auf der Suche nach neuen Entwicklern, die uns helfen, alles zu moderieren und Support zu geben.</p>
                  <p>Wenn du Interesse hast, kannst du dich gerne bei uns melden. Unsere E-Mail Adresse:
                      apply@voxelgames.de</p>
              </div>
          </div>
          <div className="App-footer">
              <p>© 2023 VoxelGames</p>
              <a href={"https://dc.voxelgames.de"}>Discord  </a><br/>
              <a href={"mailto:apply@voxelgames.de"}>Mail an VoxelGames (Bewerbung)</a><br/>
              <a href={"https://faeli.xyz"}>Faelixyz Seite</a>
          </div>
      </>
  );
}

export default App;
